@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  @apply relative flex flex-col flex-1 h-full p-0 m-0 w-full ;
}

.page-content {
  @apply flex justify-center items-center max-h-full max-w-[1440px] h-full w-full aspect-[1440/1024];
}

.page-content h3 {
  @apply text-xl font-bold mb-4;
}

.page-content p {
  @apply mb-4 text-base-content/70;
}

.rounded-border-with-shadow {
  @apply rounded-md md:rounded-2xl drop-shadow-2xl overflow-hidden;
}

.game-image {
  @apply transition-opacity duration-300 ease-in-out opacity-100 animate-pulse bg-base-content/10;
}

.game-image-loaded {
  @apply bg-transparent opacity-100 animate-none;
}
:root {
  --max-w-page: 1440;
  --max-h-page: 1024;
  --dm-page-column: 60;
  --dh-page-btn: 60;
  --dm-page: 96;
  --dw-page-body: 1238;
  --dh-page-body: 658;
  --dh-header: 110;
  --dw-icon: 30;
  --dh-icon: 30;
  --dh-logo: 46;

  --page-ratio-landscape: calc(var(--max-w-page) / var(--max-h-page));
  --page-ratio-portrait: calc(var(--max-h-page) / var(--max-w-page));
}

:root {
  --w-screen: 100%;
  --h-screen: 100%;

  --screen-ratio: calc(var(--w-screen) / var(--h-screen));

  --aspect-w-ratio: calc(1440 / min(100vw, 1440));
  --aspect-h-ratio: calc(1024 / min(100vh, 1024));
}

.main {
  @apply flex flex-col max-w-screen-2xl mx-auto  justify-start items-center;
  width: 100%
}

.page-body {
  @apply flex px-8 size-full box-border space-x-10;
}

.page-body .mainpart {
  @apply h-full grow;
}

.page-body .side {
  @apply flex flex-col justify-between grow self-stretch shrink max-w-[30%];
}

.page-body .side .side-subsub {
  @apply aspect-square;
}

.page-body.page-body-rows {
  @apply flex-col gap-4;
}

.page-body.page-body-rows .mainpart {
  @apply h-[58%] grow-0 m-0;
}

.page-body.page-body-rows .mainpart.mainpart-results {
  @apply h-[48%];
}

.page-body.page-body-rows .side-right {
  @apply w-[40%] flex flex-col;
}

.page-body.page-body-rows .side {
  @apply m-0 max-w-full flex-row gap-9;
}

.page-body.page-body-rows .side .side-sub {
  @apply w-[58%];
}

.page-body.page-body-rows .side .side-subsub {
  @apply aspect-auto size-full flex-1;
}

html[data-site-mode="wheretaken"] .page-body .side {
  @apply justify-end gap-5;
}

#adconsent-usp-link {
  bottom: 65px !important;
  font-size: 8px !important;
    padding: 2px !important;
}

.modal-toggle .modal-box {
  pointer-events: none;
}

.modal-toggle:checked .modal {
  z-index: 9999;
}

.modal-toggle:checked + .modal-box {
  pointer-events: all;
}

::view-transition-old(root) {
  animation: fade-and-scale-out 0.5s ease-in-out 1 forwards;
}

::view-transition-new(root) {
  animation: fade-and-scale-in 1s ease-in-out 1 forwards;
}

::view-transition-old(page-header) {
  animation: hide 1s ease-in-out 1 forwards;
}

::view-transition-new(page-header) {
  animation: slide-right 2s ease-in-out 1 forwards;
}

::view-transition-old(page-content) {
  animation: hide 1s ease-in-out 1 forwards;
}

::view-transition-new(page-content) {
  animation: slide-left 2.5s ease-in-out 1 forwards;
}

/* First Animation */

@keyframes fade-and-scale-in {
  from {
    opacity: 0;
    transform: scale(1);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fade-and-scale-out {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(1);
  }
}

/* Second Animation */

@keyframes hide {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes slide-left {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-right {
  from {
    opacity: 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

input[type='radio'] {
  @apply accent-accent hover:cursor-pointer;
}

input[type='checkbox'] {
  @apply accent-accent hover:cursor-pointer;
}

.Toastify__toast-container--bottom-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.tooltip .body-div {
  @apply pointer-events-none cursor-not-allowed opacity-40;
}